<template>
  <PageSection
    :id="componentId"
    :background-color="backgroundColor"
    :background-image="backgroundImage"
    :border="border"
    :border-radius="borderRadius"
    class="page-hero-section"
    data-testid="page-hero-section"
    :full-width="fullWidth"
    :horizontal-align="horizontalAlign"
    :margin="margin"
    :tag="getMdcTag('div')"
  >
    <PageContainer
      :align-items="mappedVerticalAlign"
      :class="['page-hero', image?.position ? `page-hero-${image?.position}` : '', {'page-hero-padding': contentHasPadding}]"
      data-testid="page-hero"
      display="flex"
      :flex-direction="flexDirection"
      :gap="KUI_SPACE_70"
      :padding="padding"
      :width="maxWidth"
    >
      <ProseImg
        v-if="image"
        :alt="image.alt"
        :border="image.border"
        :border-radius="image.borderRadius"
        class="page-hero-image"
        data-testid="page-hero-image"
        :margin="image.margin"
        :padding="image.padding"
        :src="image.src"
        :styles="imageAlignSelf ? `{ 'align-self': ${imageAlignSelf} }` : ''"
      />

      <div
        v-if="!!$slots.title || !!$slots.description || !!$slots.actions || !!$slots.tagline"
        :class="['page-hero-textblock', { 'page-hero-padding': !fullWidth && !!image }]"
        data-testid="page-hero-textblock"
        :style="{ color, textAlign }"
      >
        <div
          v-if="!!$slots.tagline"
          class="page-hero-tagline"
          data-testid="page-hero-tagline"
        >
          <MDCSlot
            name="tagline"
            unwrap="p"
          />
        </div>

        <component
          :is="getMdcTag(titleTag)"
          v-if="!!$slots.title"
          class="page-hero-title"
          data-testid="page-hero-title"
          :style="{ fontSize: titleFontSize, fontWeight: titleFontWeight, lineHeight: titleLineHeight }"
        >
          <MDCSlot
            name="title"
            unwrap="p"
          />
        </component>
        <div
          v-if="!!$slots.description"
          class="page-hero-description"
          data-testid="page-hero-description"
          :style="{ fontSize: descriptionFontSize, fontWeight: descriptionFontWeight, lineHeight: descriptionLineHeight }"
        >
          <MDCSlot name="description" />
        </div>

        <div
          v-if="!!$slots.actions"
          class="page-hero-actions"
          data-testid="page-hero-actions"
        >
          <MDCSlot
            name="actions"
            unwrap="p"
          />
        </div>
      </div>
    </PageContainer>
  </PageSection>
</template>

<script setup lang="ts">
import {
  KUI_SPACE_0,
  KUI_SPACE_70,
  KUI_FONT_SIZE_50,
  KUI_FONT_SIZE_70,
  KUI_LINE_HEIGHT_50,
  KUI_LINE_HEIGHT_70,
  KUI_FONT_WEIGHT_MEDIUM,
  KUI_FONT_WEIGHT_BOLD,
} from '@kong/design-tokens'
import type { PageHeroProps } from '#imports'

const {
  textAlign = 'left',
  verticalAlign = 'center',
  horizontalAlign = 'center',
  border = null,
  borderRadius = null,
  backgroundColor = '',
  color = '',
  fullWidth = false,
  maxWidth = '100%',
  margin = KUI_SPACE_0,
  padding = KUI_SPACE_0,
  image = undefined,
  backgroundImage = undefined,
  titleTag = 'h2',
  titleFontSize = KUI_FONT_SIZE_70,
  titleFontWeight = KUI_FONT_WEIGHT_BOLD,
  titleLineHeight = KUI_LINE_HEIGHT_70,
  descriptionFontSize = KUI_FONT_SIZE_50,
  descriptionFontWeight = KUI_FONT_WEIGHT_MEDIUM,
  descriptionLineHeight = KUI_LINE_HEIGHT_50,
  styles = '',
} = defineProps<PageHeroProps>()

// Inject any custom `styles` scoped by the `componentId` into the document head
const { componentId } = useCustomStyles(computed(() => styles), useAttrs().id as string)

const imageMaxWidth = computed((): string => {
  // Since we're binding directly to `style`, we need to convert potential nested
  // properties that may be defined as kebab-case in MDC to camelCase in order
  // to properly bind them to the style attribute.
  const img = convertKebabCasePropertiesToCamelCase(image)

  if (img && img?.maxWidth) {
    return img?.maxWidth
  } else {
    return img && img?.position && ['top', 'bottom'].includes(img?.position) ? '100%' : '30%'
  }
})

const imageAlignSelf = computed((): string | null => {
  // Since we're binding directly to `style`, we need to convert potential nested
  // properties that may be defined as kebab-case in MDC to camelCase in order
  // to properly bind them to the style attribute.
  const img = convertKebabCasePropertiesToCamelCase(image)

  if (img && img.alignSelf) {
    switch (img.alignSelf) {
      case 'left':
        return 'flex-start'
      case 'right':
        return 'flex-end'
      default:
        return img.alignSelf
    }
  } else {
    return null
  }
})


const contentHasPadding = computed((): boolean => !fullWidth && !image)
const flexDirection = computed((): 'row' | 'column' => {
  // Since we're binding directly to `style`, we need to convert potential nested
  // properties that may be defined as kebab-case in MDC to camelCase in order
  // to properly bind them to the style attribute.
  const img = convertKebabCasePropertiesToCamelCase(image)

  if (img) {
    return (img?.position === 'top' || img?.position === 'bottom') ? 'column' : 'row'
  } else {
    return 'row'
  }
})

// Map values to their flex value
const mappedVerticalAlign = computed(() => {
  switch (verticalAlign) {
    case 'top':
      return 'start'
    case 'bottom':
      return 'end'
    default:
      return verticalAlign
  }
})
</script>

<style scoped lang="scss">
.page-hero {
  &-section {
    :deep(.section-container) {
      height: 100%;
    }
  }

  &-padding {
    padding: var(--kui-space-70, $kui-space-70);
  }

  &-textblock {
    width: 100%;
  }

  &-right {
    .page-hero-image {
      order: 1;
    }
    .page-hero-textblock {
      order: 0;
    }
  }

  &-top {
    .page-hero-textblock {
      padding: var(--kui-space-70, $kui-space-70);
    }
  }

  &-bottom {
    .page-hero-image {
      order: 1;
    }

    .page-hero-textblock {
      order: 0;
      padding: var(--kui-space-70, $kui-space-70);
    }
  }

  &-title {
    margin-bottom: var(--kui-space-0, $kui-space-0);
    margin-top: var(--kui-space-0, $kui-space-0);
  }

  &-description {
    margin-bottom: var(--kui-space-0, $kui-space-0);
  }

  &-title + &-description {
    margin-top: var(--kui-space-40, $kui-space-40);
  }

  &-tagline {
    font-size: var(--kui-font-size-30, $kui-font-size-30);
    font-weight: var(--kui-font-weight-medium, $kui-font-weight-medium);
    line-height: var(--kui-line-height-30, $kui-line-height-30);
    margin-bottom: var(--kui-space-40, $kui-space-40);
  }

  &-actions {
    margin-top: var(--kui-space-100, $kui-space-100);
  }

  &-image {
    max-width: 100%;
    width: 100%;

    @media (min-width: $kui-breakpoint-phablet) {
      max-width: v-bind('imageMaxWidth');
    }
  }
}
</style>
